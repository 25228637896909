//** Import react


import {
    Link,
} from "react-router-dom";


const Price = () => {


    return <>
<div>
    
<div>
            testing
            </div>
 <Link to="/main">Main</Link> <br/>
 
        <Link to="/fabricjs">Fabric</Link><br/>
        
        </div>   


    </>



};


export default Price;


